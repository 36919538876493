/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Search from 'img/search.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import MenuMobile from 'components/layout/Header/MenuMobile'
import MenuDesktop from 'components/layout/Header/MenuDesktop'
import Button from 'components/elements/Buttons/Button'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import useLayout from 'hooks/useLayout'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 60px;
  z-index: 10;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  @media (max-width: 991px) {
    align-items: center;
  }
`

const Brand = styled(NavLink)`
  & > svg {
    @media (min-width: 992px) {
      height: 45px;
      width: 200px;
    }
    @media (max-width: 991px) {
      height: 35px;
      width: 200px;
    }
  }
`

const Logo = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: auto;
    width: 200px;
    object-fit: contain;
  }
  @media (max-width: 991px) {
    height: auto;
    width: 184px;
    object-fit: contain;
  }
`

const StyledButton = styled(Button)`
  @media (max-width: 596px) {
    .is-custom {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  const layout = useLayout()

  // eslint-disable-next-line
  const { fields, logo } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
          }
        }
      }

      logo: file(name: { eq: "logo" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 300, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <StyledHeader isSticky={isSticky}>
      <div className="container">
        <div className="py-3 d-flex align-items-center justify-content-end">
          <div className="me-4">
            <NavLink to="/search/">
              <Search />
            </NavLink>
          </div>
          <StyledButton
            isCustom
            onClick={() => layout.setDemoModal(true)}
            className="secondary filled"
          >
            Get a free demo
          </StyledButton>
        </div>
      </div>
      <Container className="container">
        <Brand to="/">
          <Logo
            objectFit="contain"
            image={{ localFile: logo }}
            loading="eager"
            alt=""
          />
        </Brand>

        <MenuMobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <MenuDesktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

export default Header
