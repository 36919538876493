/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>
  submenu: any
}

const Item = styled(motion.li)`
  list-style: none;

  & > a {
    color: ${(props) => props.theme.color.primary} !important;
    font-family: ${({ theme }) => theme.font.family.primary};
    font-size: 18px;
    font-weight: bold;
    &[aria-current] {
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (min-width: 992px) {
    margin-right: 3.5rem;
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;
  background: ${({ theme }) => theme.color.light};
  width: 100%;
  position: absolute;
  left: 0;

  @media screen and (min-width: 992px) {
    top: 150px;
    overflow: hidden;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  @media screen and (max-width: 991px) {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-top: unset;
    width: 100%;
    font-size: 14px;
    z-index: 2;

    & > div {
      padding: 2px 5px 2px 10px;
    }
  }
`

const Menu = styled.div`
  padding: 20px 30px 20px 95px;
  @media (min-width: 992px) {
    width: 90%;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 45px;
    height: 45px;
  }
  @media screen and (max-width: 991px) {
    width: 20px;
    height: 20px;

    & img {
      object-fit: contain !important;
    }
  }
`

const Submenu: React.FC<SubmenuProps> = ({ submenu }) => (
  <StyledSubmenu
    initial={{ height: 0 }}
    animate={{ height: 'fit-content' }}
    exit={{ height: 0 }}
    className="d-flex justify-content-center justify-content-lg-start"
  >
    <div className="container">
      <Menu className="position-relative d-flex flex-column flex-lg-row justify-content-start py-4 ps-0">
        <>
          {submenu !== null &&
            submenu?.map((f: any, index: number) => (
              <div key={index}>
                <Item>
                  <NavLink
                    className="d-flex align-items-center"
                    to={f?.link?.url || '/'}
                    key={index}
                  >
                    <StyledPlaatjie image={f.icon} alt="" />
                    <div className="ms-2 ms-lg-3 mb-lg-0">{f?.link?.title}</div>
                  </NavLink>
                </Item>
              </div>
            ))}
        </>
      </Menu>
    </div>
  </StyledSubmenu>
)

export default Submenu
