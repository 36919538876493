import React from 'react'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import { ButtonCSS } from 'components/elements/Buttons/Button'

// Images
import Close from 'img/close.inline.svg'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'

interface DemoModalProps {
  setDemoModal: Function
}

const StyledDemoModal = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  cursor: pointer;

  @media screen and (max-width: 575px) {
    padding: 0 15px;
  }
`

const Modal = styled.div`
  cursor: default;
  width: 580px;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`

const Title = styled.div`
  background-color: ${({ theme }) => theme.color.secondary};
  font-size: 40px;
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
  color: ${({ theme }) => theme.color.dark};
  border-radius: 30px 30px 0 0;
  padding: 25px 50px 15px 50px;
  position: relative;

  @media screen and (max-width: 575px) {
    font-size: 30px;
    line-height: 40px;
    padding: 25px 30px 15px 30px;
  }

  @media screen and (max-width: 350px) {
    font-size: 20px;
    line-height: 24px;
    padding: 25px 30px 15px 30px;
  }
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border-radius: 0 0 30px 30px;
  padding: 25px 50px 15px 50px;

  @media screen and (max-width: 575px) {
    padding: 25px 30px 15px 30px;
  }

  @media screen and (max-width: 350px) {
    padding: 25px 15px 15px 15px;
  }
`

const CloseWrapper = styled.button`
  position: absolute;
  right: 19px;
  top: 19px;

  & svg {
    width: 25px;
    height: 25px;
    opacity: 0.65;

    & path {
      fill: ${({ theme }) => theme.color.dark};
    }

    &:hover {
      opacity: 1;
    }
  }
`

const DemoModal: React.FC<DemoModalProps> = ({ setDemoModal }) => (
  <StyledDemoModal
    exit={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    initial={{ opacity: 0 }}
    onClick={() => setDemoModal()}
  >
    <Modal onClick={(e) => e.stopPropagation()}>
      <Title>
        Get a free demo
        <CloseWrapper
          className="position-absolute"
          onClick={() => setDemoModal(false)}
        >
          <Close />
        </CloseWrapper>
      </Title>
      <Content>
        {/* <StyledFormDuuf id={5} generate={false}>
          <FormDuufGroup /> */}
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/d/y83y-n4x9/flowize-introduction-call?hide_event_type_details=1&hide_gdpr_banner=1"
          style={{ minWidth: 320, height: 630 }}
        />
        <Helmet>
          <script
            type="text/javascript"
            src="https://assets.calendly.com/assets/external/widget.js"
            async
          />
        </Helmet>
        {/* <FormDuufSubmit />
        </StyledFormDuuf> */}
      </Content>
    </Modal>
  </StyledDemoModal>
)

export default DemoModal

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1rem;

    & .form-duuf-error {
      padding-left: 20px;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #e5e5e5;
    border-radius: 25.5px;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    padding: 0 20px;
    font-size: 16px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-label {
    padding: 0 0 5px 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-file-button {
    ${ButtonCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 14px;
  }

  & .form-duuf-submit {
    padding: 0;
    margin: 0;
    transition: all 0.3s ease;

    a,
    button {
      border-radius: 25.5px;
      line-height: 1;
      border: 3px solid ${({ theme }) => theme.color.secondary};
      font-size: 16px;
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
      font-family: ${({ theme }) => theme.font.family.secondary};
      background-color: ${({ theme }) => theme.color.secondary};
      color: ${({ theme }) => theme.color.dark};
      padding: 10px 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      transition: all 0.3s ease;
    }

    svg {
      width: 18px;
      height: 16px;
      margin-left: 5px;
      stroke-width: 3px;

      @media (max-width: 575px) {
        width: 12px;
        height: 10px;
      }
    }

    @media (max-width: 575px) {
      padding: 8px 20px;
      font-size: 14px;
    }

    // secondary color settings
    svg {
      color: ${({ theme }) => theme.color.dark};
      transition: all 0.3s ease;
    }

    &:hover {
      & a,
      button {
        border-color: ${({ theme }) => theme.color.secondaryLight};
        background-color: ${({ theme }) => theme.color.secondaryLight};
      }
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 30px;
    line-height: 40px;
    padding: 60px 40px 100px 40px;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`
