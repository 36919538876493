const mainTheme = {
  color: {
    primary: '#264052',
    primaryDark: '#222930',
    primaryLight: '#4C6170',
    secondary: '#F8CB46',
    secondaryLight: '#FADE4C',
    grey: '#F2F2F2',
    dark: '#222930',
    light: '#FFFFFF',
  },

  gradient: {
    primaryGradient: `
      background: rgb(38, 64, 82);
      background: -moz-linear-gradient(
        180deg,
        rgba(38, 64, 82, 1) 0%,
        rgba(34, 41, 48, 1) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(38, 64, 82, 1) 0%,
        rgba(34, 41, 48, 1) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(38, 64, 82, 1) 0%,
        rgba(34, 41, 48, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#264052",endColorstr="#222930",GradientType=1);
    `,
    primaryGradientLR: `
      background: rgb(38, 64, 82);
      background: -moz-linear-gradient(
        90deg,
        rgba(38, 64, 82, 1) 0%,
        rgba(34, 41, 48, 1) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(38, 64, 82, 1) 0%,
        rgba(34, 41, 48, 1) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(38, 64, 82, 1) 0%,
        rgba(34, 41, 48, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#264052",endColorstr="#264052",GradientType=1);
    `,
    secondaryGradientLR: `
      background: rgb(248, 203, 70);
      background: -moz-linear-gradient(
        90deg,
        rgba(248, 203, 70, 1) 0%,
        rgba(250, 222, 76, 1) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(248, 203, 70, 1) 0%,
        rgba(250, 222, 76, 1) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(248, 203, 70, 1) 0%,
        rgba(250, 222, 76, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F8CB46",endColorstr="#FADE4C",GradientType=1);
    `,
    opaqueGradient: `background: rgb(38,64,82);
background: -moz-linear-gradient(180deg, rgba(38,64,82,0) 30%, rgba(38,64,82,1) 80%);
background: -webkit-linear-gradient(180deg, rgba(38,64,82,0) 30%, rgba(38,64,82,1) 80%);
background: linear-gradient(180deg, rgba(38,64,82,0) 30%, rgba(38,64,82,1) 80%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#264052",endColorstr="#264052",GradientType=1);`,
    greyGradient: `background: rgb(242,242,242);
background: -moz-linear-gradient(100deg, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 50%, rgba(242,242,242,0) 90%);
background: -webkit-linear-gradient(100deg, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 50%, rgba(242,242,242,0) 90%);
background: linear-gradient(100deg, rgba(242,242,242,1) 0%, rgba(242,242,242,1) 50%, rgba(242,242,242,0) 90%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f2f2",endColorstr="#f2f2f2",GradientType=1);`,
    primaryGradientOpaque: `background: rgb(38,64,82);
background: -moz-linear-gradient(100deg, rgba(38,64,82,1) 0%, rgba(38,64,82,1) 50%, rgba(38,64,82,0) 90%);
background: -webkit-linear-gradient(100deg, rgba(38,64,82,1) 0%, rgba(38,64,82,1) 50%, rgba(38,64,82,0) 90%);
background: linear-gradient(100deg, rgba(38,64,82,1) 0%, rgba(38,64,82,1) 50%, rgba(38,64,82,0) 90%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#264052",endColorstr="#264052",GradientType=1);`,
    primaryGradientOpaqueMobile: `background: rgb(38,64,82);
background: -moz-linear-gradient(100deg, rgba(38,64,82,1) 0%, rgba(38,64,82,1) 50%, rgba(38,64,82,0.5) 90%);
background: -webkit-linear-gradient(100deg, rgba(38,64,82,1) 0%, rgba(38,64,82,1) 50%, rgba(38,64,82,0.5) 90%);
background: linear-gradient(100deg, rgba(38,64,82,1) 0%, rgba(38,64,82,1) 50%, rgba(38,64,82,0.5) 90%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#264052",endColorstr="#264052",GradientType=1);`,
  },

  font: {
    family: {
      primary: 'Century Gothic, Arial',
      secondary: 'Century Gothic, Arial',
    },

    size: {
      tiny: '10px',
      small: '14px',
      regular: '18px',
      medium: '20px',
      big: '25px',
      large: '30px',
      huge: '40px',
      mega: '50px',
      giga: '75px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
