import React from 'react'

// Elements
import { ButtonCSS } from 'components/elements/Buttons/Button'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Third Party
import styled from 'styled-components'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    height: 100%;

    &-submit {
      display: flex;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 25.5px;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    padding: 0 20px;

    &::placeholder {
      font-style: italic;
      font-size: 14px;
    }
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonCSS};

    background-color: ${({ theme }) => theme.color.secondary};
    border-color: ${({ theme }) => theme.color.secondary};

    a,
    button {
      padding: 15px 20px;
      color: ${({ theme }) => theme.color.primary};
    }

    svg {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonCSS};

    background-color: ${({ theme }) => theme.color.secondary};
    border-color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 12px 20px;

    a,
    button {
      color: ${({ theme }) => theme.color.primary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    svg {
      color: ${({ theme }) => theme.color.primary};
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const StyledNewsletter = styled.div`
  & h3 {
    font-size: 20px;
    line-height: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-bottom: 0;
  }

  & p {
    font-size: 14px;
    line-height: 17px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

interface NewsLetterProps {}

const NewsLetter: React.FC<NewsLetterProps> = () => (
  <StyledNewsletter className="pb-5">
    <h3>STAY IN TOUCH</h3>
    <p>Receive our latest news directly in your mailbox</p>
    <StyledFormDuuf id={2} generate={false}>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row">
            <div className="col-sm-7 position-relative">
              <FormDuufGroup />
            </div>
            <div className="col-sm-5 mt-4 mt-sm-0 position-relative">
              <FormDuufSubmit />
            </div>
          </div>
        </div>
      </div>
    </StyledFormDuuf>
  </StyledNewsletter>
)

export default NewsLetter
