import React from 'react'
import styled, { css } from 'styled-components'

// Components
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import Arrow from 'img/arrow.inline.svg'

export const ButtonCSS = css`
  border: 3px solid ${({ theme }) => theme.color.dark};
  color: ${({ theme }) => theme.color.dark};
  font-family: ${({ theme }) => theme.font.family.secondary};
  border-radius: 25.5px;
  line-height: 1;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transition: all 0.3s ease;

  a,
  button,
  .is-custom {
    padding: 15px 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  svg {
    width: 18px;
    height: 16px;
    margin-left: 5px;
    stroke-width: 3px;
    @media (max-width: 575px) {
      width: 12px;
      height: 10px;
    }
  }

  @media (max-width: 575px) {
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 50px;
  }

  ${(props) =>
    Object.entries(props.theme.color).map(
      ([key, value]) =>
        `
          &.${key} {
            color: ${value};
            border-color: ${value};

            &:not(.filled) a, :not(.filled) .is-custom {
              color: ${value};
            }

            &:hover {
              background-color: ${value};
            }
          }

          &.hover-${key} {
            &:hover {
              & a, .is-custom {
                color: ${value};
              }

              & svg {
                path {
                  fill: ${value};
                }
              }
            }
          }

          &.${key}.transparent {
            background-color: transparent;
            border-color: transparent;

            &.colored a {
              color: ${value};
            }

            a {
              color: ${value};
            }

            svg {
              path {
                transition: all 0.3s ease;
                fill: ${value};
              }
              color: ${value};
              transition: all 0.3s ease;
            }
          }

          &.${key}.filled-white {
            background-color: ${props.theme.color.light};
            border-color: ${props.theme.color.light};

            &.colored a {
              color: ${value};
            }

            a {
              color: ${props.theme.color.dark};
            }

            svg {
              color: ${value};
            }
          }

          &.${key}.filled {
            background-color: ${value};
            border-color: ${value};

            a, .is-custom {
              color: ${
                key === 'secondary'
                  ? props.theme.color.dark
                  : props.theme.color.light
              };
            }

            svg {
              color: ${
                key === 'secondary'
                  ? props.theme.color.dark
                  : props.theme.color.light
              };
              transition: all 0.3s ease;
            }

            &:hover {
              border-color: ${
                key === 'secondary'
                  ? props.theme.color.secondaryLight
                  : props.theme.color.primaryDark
              };
              background-color: ${
                key === 'secondary'
                  ? props.theme.color.secondaryLight
                  : props.theme.color.primaryDark
              };
            }
          }
        `
    )}
`

// @ts-ignore
const StyledArrow = styled(Arrow)<{ className: string }>``

const StyledButton = styled.button`
  ${ButtonCSS}

  ${(props) =>
    props.type !== undefined &&
    css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em 1em 0.5em 1em;
    `};
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  isCustom?: boolean
  to?: string
  children: any
  className?: string
  arrow?: boolean
  type?: 'submit' | 'button'
  target?: string
  onClick?: () => void
}

const Button: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  isCustom = false,
  to = '',
  children,
  className = '',
  arrow = false,
  type = undefined,
  target = '',
  onClick = () => null,
}) => (
  <StyledButton onClick={onClick} type={type} className={className}>
    {to === '' || isCustom ? (
      <div className="is-custom">
        <span>{children}</span>
        {arrow && <StyledArrow className="arrow" />}
      </div>
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        <span>{children}</span>

        {arrow && <StyledArrow className="arrow" />}
      </ButtonShell>
    )}
  </StyledButton>
)

export default Button
