/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
  pageContext?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
  pageContext?: any
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
  pageContext,
}) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: loadable(
      () => import('components/flex/Banner/BannerShell')
    ),
    [`${prefix}_Flex_Form`]: loadable(
      () => import('components/flex/Form/FormShell')
    ),
    [`${prefix}_Flex_Text`]: loadable(
      () => import('components/flex/Text/TextShell')
    ),
    [`${prefix}_Flex_PostTypes`]: loadable(
      () => import('components/flex/Posts/PostsShell')
    ),
    [`${prefix}_Flex_Columns`]: loadable(
      () => import('components/flex/Columns/Columns')
    ),
    [`${prefix}_Flex_Components`]: loadable(
      () => import('components/flex/Components/ComponentsShell')
    ),
    [`${prefix}_Flex_Emptyspace`]: loadable(
      () => import('components/flex/Emptyspace')
    ),
    [`${prefix}_Flex_Fragment`]: loadable(
      () => import('components/flex/Fragment')
    ),
  }

  if (!fields.flex) {
    return null
  }

  return (
    <>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
            pageContext={pageContext}
          />
        )
      })}
    </>
  )
}

export default FlexContentHandler
