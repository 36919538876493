import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  padding: 30px 0 0;

  & > ul {
    padding: 100px 0 0;
    list-style-type: none;
    text-align: center;
    margin: 0 auto;

    @media (min-width: 576px) {
      width: 60%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }

    & li {
      padding: 0;

      & a {
        font-size: 24px;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        position: relative;
        width: auto;

        &::before {
          content: '';
          background-color: ${({ theme }) => theme.color.secondary};
          height: 1px;
          width: 110px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;

          @media (max-width: 991px) {
            display: none;
          }
        }
      }

      &:last-child a {
        &::before {
          content: none;
        }
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const NavCollapse = styled.div`
  position: relative;
  top: 4px;
`

interface MenuMobileProps {
  fields: any
  isSticky?: boolean
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <NavCollapse className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul className="w-75 mx-auto">
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </NavCollapse>
  )
}

export default MenuMobile
