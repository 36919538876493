import React from 'react'

// Components
import Submenu from 'components/layout/Header/Submenu'

import NavLink from 'components/shared/NavLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Images
import Chevron from 'img/down-chevron.inline.svg'

const Item = styled(motion.li)<{ selected: boolean; hovered: boolean }>`
  & a {
    color: ${(props) => props.theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 14px;
    /* position: relative; */
    transition: all 0.3s ease;
    ${(props) =>
      props.selected &&
      css`
        background-color: ${({ theme }) => theme.color.light};
        color: ${({ theme }) => theme.color.primary} !important;
      `}
    @media (min-width: 1440px) {
      padding: 10px 40px 20px 0px;
      ${(props) =>
        props.selected &&
        css`
          padding: 20px 55px 20px 10px;
        `}
    }
    @media (max-width: 991px) {
      text-align: center !important;
      padding: 1rem !important;
    }

    &[aria-current] {
      color: ${({ theme }) => theme.color.secondary};

      &::before {
        background-color: ${({ theme }) => theme.color.secondary};
        height: 3px;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.secondary};

      &::before {
        background-color: ${({ theme }) => theme.color.secondary};
      }
    }

    &::before {
      @media (min-width: 1199px) {
        content: '';
        position: absolute;
        width: 150px;
        height: 1px;
        background-color: ${({ theme }) => theme.color.light};
        top: -10px;
        left: 0;
        transition: all 0.3s ease;
      }
    }
  }

  @media (min-width: 992px) {
    margin-bottom: -1rem;
  }
`

const ChevronWrapper = styled.div<{ isBlack: boolean }>`
  z-index: 99;
  & svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    fill: white;

    ${(props) =>
      props.isBlack
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }

  @media screen and (max-width: 991px) {
    margin-left: 10px;
  }
`

const StyledNavLink = styled(NavLink)`
  font-family: ${({ theme }) => theme.font.family.primary};
  font-weight: bold;
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

interface MenuProps {
  fields: any
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  const handleTap = (index: number) => {
    if (open === index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }

  return (
    <>
      {fields.header.menuHeader.map(
        ({ link: { url, title }, submenu }: any, index: number) => (
          <Item
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onHoverStart={() => setOpen(index)}
            onHoverEnd={() => setOpen(-1)}
            // onTap={(e) => handleTap(e, index)}
            selected={open === index && submenu}
            hovered={open === index}
          >
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
              <StyledNavLink to={url} className="position-relative text-white">
                {title}
              </StyledNavLink>
              {submenu && (
                <ChevronWrapper
                  className=" d-flex d-lg-none"
                  isBlack={open === index}
                  onClick={() => handleTap(index)}
                >
                  <Chevron />
                </ChevronWrapper>
              )}
            </div>
            {submenu && (
              <AnimatePresence>
                {open === index && (
                  <Submenu submenu={submenu} fields={fields} />
                )}
              </AnimatePresence>
            )}
          </Item>
        )
      )}
    </>
  )
}

export default Menu
