/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Images
import flowizeicon from 'img/flowizeicon.svg'
import checkmark from 'img/checkmark.svg'

// Services
import convertStyle from 'services/convertStyle'

// Elements
import Button from 'components/elements/Buttons/Button'
import useLayout from 'hooks/useLayout'

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.light};
  line-height: 30px;

  @media (min-width: 576px) {
    font-size: 16px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  h1 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.giga};
      line-height: 92px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }
  }

  h2 {
    padding-bottom: 30px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: 61.3px;
    }

    @media (max-width: 991px) {
      padding-bottom: 10px;
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }

  h3 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: ${({ theme }) => theme.font.size.big};
    }
  }

  h4 {
    padding-bottom: 10px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: ${({ theme }) => theme.font.size.medium};
    }
  }

  h5 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.big};
      line-height: 30.65px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.regular};
      line-height: ${({ theme }) => theme.font.size.regular};
    }
  }

  h6 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-height: 24.52px;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.small};
      line-height: ${({ theme }) => theme.font.size.small};
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    &.checkmark {
      padding-left: 50px;

      & li {
        font-size: 20px;
        line-height: 35px;
        position: relative;
        list-style: none;

        &:before {
          content: '';
          position: absolute;
          top: 10px;
          left: -30px;
          width: 20px;
          height: 14px;
          background-image: url(${checkmark});
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    &.flowizeicon {
      padding-left: 40px;

      & li {
        position: relative;
        list-style: none;

        @media (min-width: 576px) {
          font-size: 20px;
          line-height: 50px;
        }
        @media (max-width: 575px) {
          display: flex;
          font-size: 14px;
          line-height: 30px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 14px;
          left: -40px;
          width: 25px;
          height: 25px;
          background-image: url(${flowizeicon});
          background-size: contain;
          background-repeat: no-repeat;

          @media screen and (max-width: 575px) {
            top: 3px;
          }
        }
      }
    }

    & li {
    }
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.secondary};
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }
`

const findAndReplace = (content: string) => {
  const layout = useLayout()

  return parse(content, {
    replace: (domNode) => {
      const { name, attribs, children }: any = domNode

      if (name === 'a' && attribs.class?.includes('button')) {
        if (attribs.href.includes('#get-demo')) {
          return (
            <Button
              arrow={attribs.class.includes('arrow')}
              className={attribs.class}
              isCustom
              onClick={() => layout.setDemoModal(true)}
              target={attribs.target}
            >
              {domToReact(children, {})}
            </Button>
          )
        }

        return (
          <Button
            arrow={attribs.class.includes('arrow')}
            className={attribs.class}
            to={attribs.href}
            target={attribs.target}
          >
            {domToReact(children, {})}
          </Button>
        )
      }

      if (name === 'a') {
        const attributes = attribs

        if (attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return <a {...attributes}>{domToReact(children, {})}</a>
        }

        return (
          <Link to={attributes.href} {...attributes}>
            {domToReact(children, {})}
          </Link>
        )
      }

      return null
    },
  })
}

interface ParseContentProps {
  content: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return <Content className={className}>{findAndReplace(content)}</Content>
}

export default ParseContent
