/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Images
import FlowizeBlocks from 'img/big_flowize_blocks.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import Twitter from 'img/twitter.inline.svg'
import Youtube from 'img/youtube.inline.svg'

// Components
import NewsLetter from 'components/layout/Footer/Newsletter'

// Third Party
import styled from 'styled-components'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.primaryDark};
  color: ${(props) => props.theme.color.light};
  height: fit-content;
  position: relative;

  @media (min-width: 1025px) {
    padding: 120px 0 110px 0; //fml
  }

  & h2 {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 80%;
      bottom: 10px;
      background-color: ${({ theme }) => theme.color.light};

      @media screen and (max-width: 991px) {
        content: none;
      }
    }
  }

  & ul {
    padding-left: 10px;
  }

  & li {
    list-style: none;
    position: relative;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 0;
    transition: all 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      left: -10px;
      top: 16px;
      background-color: ${({ theme }) => theme.color.light};
      border-radius: 100%;
      transition: all 0.3s ease;
    }

    &:hover {
      color: ${({ theme }) => theme.color.secondary};

      &::before {
        background-color: ${({ theme }) => theme.color.secondary};
      }
    }
  }

  @media screen and (max-width: 991px) {
    & h2 {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    & li {
      padding: 4px 0;

      &:before {
        top: 11px;
      }
    }
  }
`

const FooterContainer = styled.div`
  position: relative;
  z-index: 2;
`

const BackgroundSVG = styled.div`
  & svg {
    position: absolute;
    bottom: 0;
    right: 0;
    height: auto;
    width: 100%;
    z-index: 1;
  }
`

const SocialLink = styled.a`
  & svg {
    path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    & svg {
      path {
        fill: ${({ theme }) => theme.color.secondary};
      }
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menus {
            title
            menu {
              link {
                title
                url
              }
            }
          }
          socials {
            facebook {
              url
            }
            linkedin {
              url
            }
            twitter {
              url
            }
            youtube {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter className="overflow-hidden">
      <BackgroundSVG>
        <FlowizeBlocks />
      </BackgroundSVG>
      <FooterContainer className="container py-lg-5 py-4">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="row">
              {fields?.footer?.menus?.map((m, mainIndex) => (
                <div key={`menu-${mainIndex}`} className="col-lg pb-lg-0 pb-3">
                  <h2 className="pb-lg-4 pb-1">{m?.title}</h2>
                  <ul>
                    {m?.menu?.map(
                      ({ link: { url, title } }: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index}>
                          <Link to={url}>{title}</Link>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ps-lg-5">
              <NewsLetter />
            </div>
            <div className="d-flex ps-lg-5">
              <div className="me-4">
                <SocialLink href={fields?.footer?.socials?.facebook?.url}>
                  <Facebook />
                </SocialLink>
              </div>
              <div className="me-4">
                <SocialLink href={fields?.footer?.socials?.linkedin?.url}>
                  <Linkedin />
                </SocialLink>
              </div>
              <div className="me-4">
                <SocialLink href={fields?.footer?.socials?.twitter?.url}>
                  <Twitter />
                </SocialLink>
              </div>
              <div>
                <SocialLink href={fields?.footer?.socials?.youtube?.url}>
                  <Youtube />
                </SocialLink>
              </div>
            </div>
          </div>
        </div>
      </FooterContainer>
    </StyledFooter>
  )
}

export default Footer
