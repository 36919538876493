import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

// Theme
import Theme from 'styles/Global'

// Components
import Footer from 'components/layout/Footer/Footer'
import Header from 'components/layout/Header/Header'
import DemoModal from 'components/elements/Modals/DemoModal'

// CSS
import 'swiper/swiper.min.css'
import 'styles/all.css'

// Third party
import { AnimatePresence } from 'framer-motion'

export interface LayoutContextProps {
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
  setDemoModal: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  translations: [],
  setIsScrollable: (bool: boolean) => bool,
  setDemoModal: (bool: boolean) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>``

interface LayoutProps {
  children: any
  locale?: string
  translations?: any
  location?: any
}

const Layout: React.FC<LayoutProps> = ({
  location,
  children,
  locale,
  translations,
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const [demoModal, setDemoModal] = useState<boolean>(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (location && location.hash === '#get-demo') {
      navigate('#blank')
      setDemoModal(true)
    }
  }, [location])

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider
        // @ts-ignore
        value={{ setDemoModal, setIsScrollable, locale, translations }}
      >
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Content isSticky={isSticky}>{children}</Content>
        <Footer />
      </LayoutContext.Provider>
      <AnimatePresence>
        {demoModal && <DemoModal setDemoModal={setDemoModal} />}
      </AnimatePresence>
    </Theme>
  )
}

export default Layout
